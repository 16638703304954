import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Input, InputGroup, Row} from "reactstrap";

class UserForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userAdd: {pseudo: '', pass: ''},
        };
    }

    onChangeAddUser(e) {

        let userAdd                   = this.state.userAdd;
        userAdd[e.currentTarget.name] = e.currentTarget.name === 'pseudo' ? e.currentTarget.value.toLowerCase() : e.currentTarget.value;
        this.setState({userAdd});

    }

    handleAddUser() {
        this.props.addUserHandler(this.state.userAdd);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.userAdded.pseudo !== prevProps.userAdded.pseudo) {
            this.setState({userAdd: {pseudo: '', pass: ''}});
        }
    }

    render() {

        return (
            <>
                <div>
                    <Row className={"mb-4"}>
                        <Col>
                            <h2>Créer un utilisateur SFTP</h2>
                        </Col>
                    </Row>
                    <Row className={"mb-4"}>
                        <Col>
                            <InputGroup>
                                <Input placeholder="Identifiant" name={"pseudo"} type={"text"} onChange={this.onChangeAddUser.bind(this)}
                                       value={this.state.userAdd.pseudo}/>
                            </InputGroup>
                            <br/>
                            <InputGroup>
                                <Input placeholder="Mot de passe" name={"pass"} type={"password"} onChange={this.onChangeAddUser.bind(this)}
                                       value={this.state.userAdd.pass}/>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button onClick={this.handleAddUser.bind(this)}>Ajouter</Button>
                        </Col>
                    </Row>
                </div>

            </>
        );

    }
}

UserForm.propTypes = {
    addUserHandler: PropTypes.func.isRequired,
    userAdded     : PropTypes.object.isRequired
};

export default UserForm;
