import React from 'react';
import PropTypes from 'prop-types';

import {Button, Col, Input, FormGroup, Container, Row} from 'reactstrap';
import Label from "reactstrap/es/Label";
import {withAxios} from "react-axios";
import {ERROR_NOTIFICATION} from "../../constants/notificationConstants";
import imgLogo from '../../assets/autajon.png';

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            login: {pseudo: '', password: ''},
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();

        let data = new FormData();
        data.append("username", this.state.login.pseudo);
        data.append("password", this.state.login.password);
        data.append("time", Math.floor(Date.now() / 1000));

        fetch('api/loginUserAdmin.php', {
            method: 'POST',
            body  : data,
        })
            .then(response => response.json()
                .then((result) => {
                    if (result.success) {
                        this.props.handleLogin(result.success.token, this.state.login.pseudo);
                    }
                    else if (result.error) {
                        this.props.addAlert([{message: result.error.text, tag: ERROR_NOTIFICATION}]);
                    }

                }),
            );

    }

    handleChange(e) {

        let login                   = this.state.login;
        login[e.currentTarget.name] = e.currentTarget.value;
        this.setState({login});

    }

    render() {

        return (
            <>

                <Container>
                    <Row className={"mb-4"}>
                        <Col md={{size: 6, offset: 3}} className={"text-center"}>
                            <img src={imgLogo} alt={"Logo Autajon"}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{size: 4, offset: 4}}>
                            <form onSubmit={this.handleSubmit}>
                                <div className={"d-flex justify-content-center"}>
                                    <FormGroup>
                                        <Label>{"Identifiant"}</Label>
                                        <br/>
                                        <Input
                                            type={"text"}
                                            name={"pseudo"}
                                            onChange={this.handleChange}
                                        />
                                    </FormGroup>
                                </div>
                                <div className={"d-flex justify-content-center"}>
                                    <FormGroup>
                                        <Label>{"Mot de passe"}</Label>
                                        <br/>
                                        <Input
                                            type={"password"}
                                            name={"password"}
                                            onChange={this.handleChange}
                                        />
                                    </FormGroup>
                                </div>
                                <div className={"d-flex justify-content-center"}>
                                    <Button type="submit">Se connecter</Button>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </Container>

            </>
        );

    }
}

Login.propTypes = {
    style      : PropTypes.string,
    handleLogin: PropTypes.func.isRequired,
    addAlert   : PropTypes.func
};

export default withAxios(Login);
