import React, {Component} from 'react';

import './css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Login from "./components/container/login";
import Header from "./components/container/Header";
import {getAccessToken, getUsername, setAccessToken, setUsername} from "./helper/userHelper";
import UserManager from "./components/container/UserManager";
import Notification from "./components/Notification/Notification";
import Konami from 'react-konami';
import SweetAlert from 'sweetalert2-react';
import {library} from '@fortawesome/fontawesome-svg-core'
import {faEdit} from '@fortawesome/free-solid-svg-icons'
import SweetAlert2 from 'react-bootstrap-sweetalert';
import {ERROR_NOTIFICATION} from "./constants/notificationConstants";

library.add(faEdit);

class App extends Component {

    constructor(props) {
        super(props);

        let username = '';
        let token    = getAccessToken();

        if (token !== null) {
            username = getUsername();
        }

        this.state = {
            logged                     : token !== null,
            pseudo                     : username,
            notifications              : [],
            showSwal                   : false,
            showAdminUpdatePasswordSWAL: false
        };

        this.login                     = this.login.bind(this);
        this.handleDeconnect           = this.handleDeconnect.bind(this);
        this.addAlert                  = this.addAlert.bind(this);
        this.handleUpdateAdminPassword = this.handleUpdateAdminPassword.bind(this);
    }

    getAlerts() {
        const alerts = [];
        this.state.notifications.forEach((item, index) => {

            let alert = (

                <Notification
                    key={Math.random(0, 1000) + index.toString()}
                    text={item.message}
                    type={item.tag}
                    position="top-right"
                />
            );
            alerts.push(alert);
        });

        return alerts.length > 0 ? alerts : null;
    }

    addAlert(notifications) {
        this.setState({notifications})
    }

    login(token, pseudo) {
        setAccessToken(token);
        setUsername(pseudo);
        this.setState({logged: true, notifications: [], pseudo: pseudo});
    }

    handleDeconnect() {
        setAccessToken(null);
        this.setState({logged: false, notifications: []});
    }

    handleUpdateAdminPassword(password) {
        let data = new FormData();
        data.append("pseudo", this.state.pseudo);
        data.append("password", password);

        fetch('api/updateUserAdminPassword.php?&token=' + getAccessToken(), {
            method: 'POST',
            body  : data,
        })
            .then(response => response.json()
                .then((result) => {
                    console.log(result);
                    if (result.success) {
                        this.setState({showSWALConfirmEdit: true});
                    }
                    else if (result.error) {
                        this.props.addAlert([{message: result.error.text, tag: ERROR_NOTIFICATION}]);
                    }

                }).catch(error => {
                    console.log(error);
                }),
            );
    }

    konamiCode() {
        this.setState({showSwal: true});
    }

    render() {

        let render;

        if (!this.state.logged) {
            render = (<Login handleLogin={this.login} addAlert={this.addAlert}/>);
        }
        else if (this.state.logged) {
            render = (<UserManager handleDeconnect={this.handleDeconnect} addAlert={this.addAlert}/>);
        }

        return (
            <>

                {
                    this.state.showSWALConfirmEdit &&
                    <SweetAlert2
                        success
                        title="Modification mot de passe admin"
                        onConfirm={() => this.setState({showSWALConfirmEdit: false})}
                    >
                        Le mot de passe admin a bien été modifié
                    </SweetAlert2>
                }
                {this.getAlerts()}

                <Konami easterEgg={this.konamiCode.bind(this)}/>
                <SweetAlert
                    show={this.state.showSwal}
                    title="Konami code"
                    text="Bienvenue sur l'outil de gestion SFTP Autajon !"
                    onConfirm={() => this.setState({showSwal: false})}
                />

                <Header style={{height: "58px"}} logged={this.state.logged} handleDeconnect={this.handleDeconnect}
                        handleChangeAdminPassword={this.handleUpdateAdminPassword}/>
                {render}
            </>
        );
    }
}

export default App;
