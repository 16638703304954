import React from 'react';
import PropTypes from 'prop-types';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import '../../css/notification.css';
import {
    ERROR_NOTIFICATION,
    INFO_NOTIFICATION,
    SUCCESS_NOTIFICATION,
    WARNING_NOTIFICATION
} from '../../constants/notificationConstants';

class Notification extends React.Component {

    constructor() {
        super();
    }

    componentDidMount() {

        let notifProps = {
            position        : this.props.position,
            autoClose       : this.props.autoClose || 5000,
            hideProgressBar : this.props.hideProgressBar || true,
            closeOnClick    : this.props.closeOnClick || true,
            pauseOnHover    : this.props.pauseOnHover || true,
            draggable       : this.props.draggable || true,
            newestOnTop     : this.props.newestOnTop || false,
            draggablePercent: 50
        };

        switch (this.props.type) {
            case SUCCESS_NOTIFICATION:
                toast.success(this.props.text, notifProps);
                break;
            case ERROR_NOTIFICATION:
                toast.error(this.props.text, notifProps);
                break;
            case WARNING_NOTIFICATION:
                toast.warn(this.props.text, notifProps);
                break;
            case INFO_NOTIFICATION:
                toast.info(this.props.text, notifProps);
                break;
            default:
                toast(this.props.text, notifProps);
        }

    }

    render() {

        return (
            <div>
                <ToastContainer/>
            </div>
        );

    }
}

Notification.propTypes = {
    text           : PropTypes.string.isRequired,
    position       : PropTypes.string.isRequired,
    type           : PropTypes.string,
    autoClose      : PropTypes.number,
    newestOnTop    : PropTypes.bool,
    hideProgressBar: PropTypes.bool,
    closeOnClick   : PropTypes.bool,
    pauseOnHover   : PropTypes.bool,
    draggable      : PropTypes.bool
};

export default Notification;
