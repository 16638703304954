import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Button, Col, ListGroup, ListGroupItem, Row} from "reactstrap";

import '../../css/UserList.css';

class UserList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    handleDeleteUser(userId, del = false) {
        this.props.deleteUserHandler(userId, del);
    }

    handleEditUser(userId) {
        this.props.editUserHandler(userId);
    }

    render() {

        let users = this.props.sftpUsers.map((user) => {
            return (
                <ListGroupItem id={user.id}>{user.pseudo}
                    <Button
                        close
                        onClick={this.handleDeleteUser.bind(this, user.id, false)}
                    />
                    <Button className={"edit_button"} onClick={this.handleEditUser.bind(this, user.id)}>
                        <FontAwesomeIcon icon={"edit"}/>

                    </Button>
                </ListGroupItem>
            );
        });

        if (users.length === 0) {
            users = (
                <ListGroupItem>Aucune utilisateur</ListGroupItem>
            );
        }

        return (
            <>
                <Row className={"mb-4"}>
                    <Col>
                        <h2>Liste des utilisateurs </h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ListGroup>
                            {users}
                        </ListGroup>
                    </Col>
                </Row>
            </>
        );

    }
}

UserList.propTypes = {
    sftpUsers        : PropTypes.array,
    deleteUserHandler: PropTypes.func.isRequired,
    editUserHandler  : PropTypes.func.isRequired,
};

export default UserList;
