// @flow

export const SUCCESS_NOTIFICATION = 'SUCCESS_NOTIFICATION';
export const ERROR_NOTIFICATION = 'ERROR_NOTIFICATION';
export const WARNING_NOTIFICATION = 'WARNING_NOTIFICATION';
export const INFO_NOTIFICATION = 'INFO_NOTIFICATION';

export default {
    SUCCESS_NOTIFICATION,
    ERROR_NOTIFICATION,
    WARNING_NOTIFICATION,
    INFO_NOTIFICATION,
};
