import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from "reactstrap";
import Container from "reactstrap/es/Container";
import SweetAlert2 from 'react-bootstrap-sweetalert';
import {withAxios} from 'react-axios'
import {getAccessToken} from "../../helper/userHelper";
import {ERROR_NOTIFICATION} from "../../constants/notificationConstants";
import UserList from "../userManager/UserList";
import UserForm from "../userManager/UserForm";

class UserManager extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userAdded            : {pseudo: '', pass: ''},
            userToEdit           : {id: '', pseudo: '', pass: ''},
            sftpUsers            : [],
            showSWAL             : false,
            showSWALConfirmEdit  : false,
            showDeleteSWAL       : false,
            showEditSWAL         : false,
            showSWALConfirmDelete: false,
            userToDelete         : 0
        };

        this.handleAddUser    = this.handleAddUser.bind(this);
        this.handleDeleteUser = this.handleDeleteUser.bind(this);
        this.handleEditUser   = this.handleEditUser.bind(this);
    }

    componentDidMount() {

        this.getUserList();

    }

    getUserList() {
        fetch('api/getUserList.php?token=' + getAccessToken())
            .then(response => response.json()
                .then((result) => {
                    if (result.success) {
                        this.setState({sftpUsers: result.success.users});
                    }
                    else if (result.error) {
                        if (Number(result.error.code) === 401) {
                            this.props.handleDeconnect();
                        }
                        this.props.addAlert([{message: result.error.text, tag: ERROR_NOTIFICATION}]);
                    }

                }),
            );
    }

    handleAddUser(userData) {

        if (userData.pseudo === '') {
            this.props.addAlert([{message: 'Identifiant obligatoire !', tag: ERROR_NOTIFICATION}]);
            return null;
        }
        if (userData.pass === '') {
            this.props.addAlert([{message: 'Mot de passe obligatoire !', tag: ERROR_NOTIFICATION}]);
            return null;
        }

        if (this.state.sftpUsers.some(user => user.pseudo === userData.pseudo)) {
            this.props.addAlert([{message: 'Utilisateur déjà existant', tag: ERROR_NOTIFICATION}]);
            return null;
        }

        let data = new FormData();
        data.append("pseudo", userData.pseudo);
        data.append("password", userData.pass);

        fetch('api/addSFTPUser.php?&token=' + getAccessToken(), {
            method: 'POST',
            body  : data,
        })
            .then(response => response.json()
                .then((result) => {
                    if (result.success) {
                        this.getUserList();
                        this.setState({showSWAL: true, userAdded: {pseudo: userData.pseudo, pass: userData.pass}});
                    }
                    else if (result.error) {
                        if (Number(result.error.code) === 401) {
                            this.props.handleDeconnect();
                        }
                        this.props.addAlert([{message: result.error.text, tag: ERROR_NOTIFICATION}]);
                    }

                }).catch(error => {
                    console.log(error);
                }),
            );
    }

    handleDeleteUser(id, del = false) {

        if (del) {
            fetch('api/deleteSFTPUser.php?id=' + id + '&token=' + getAccessToken(), {
                method: 'DELETE',
            })
                .then(response => {
                        console.log(response);
                        response.json()
                            .then((result) => {
                                if (result.success) {
                                    this.getUserList();
                                }
                                else if (result.error) {
                                    if (Number(result.error.code) === 401) {
                                        this.props.handleDeconnect();
                                    }
                                    this.props.addAlert([{message: result.error.text, tag: ERROR_NOTIFICATION}]);
                                }

                                this.setState({showDeleteSWAL: false, showSWALConfirmDelete: true});

                            }).catch(error => {
                            console.log(error);
                        });
                    }
                ).catch(error => {
                console.log(error);
            });
        }
        else {
            this.setState({showDeleteSWAL: true, userToDelete: id});
        }

    }

    handleEditUser(userId, password) {

        if (!this.state.showEditSWAL) {
            let user = this.state.sftpUsers.filter((sftpUser) => {
                return sftpUser.id === userId;
            });

            this.setState({showEditSWAL: true, userToEdit: {id: userId, pseudo: user[0].pseudo, pass: user[0].pass}});
        }
        else {
            let data = new FormData();
            data.append("pseudo", this.state.userToEdit.pseudo);
            data.append("password", password);

            fetch('api/updateSFTPUserPassword.php?&token=' + getAccessToken(), {
                method: 'POST',
                body  : data,
            })
                .then(response => response.json()
                    .then((result) => {
                        if (result.success) {
                            this.getUserList();
                            this.setState({showSWALConfirmEdit: true, showEditSWAL: false});
                        }
                        else if (result.error) {
                            if (Number(result.error.code) === 401) {
                                this.props.handleDeconnect();
                            }
                            this.props.addAlert([{message: result.error.text, tag: ERROR_NOTIFICATION}]);
                        }

                    }).catch(error => {
                        console.log(error);
                    }),
                );

        }

    }

    cancelUserEdit() {
        this.setState({showEditSWAL: false});
    }

    render() {

        return (
            <>

                {
                    this.state.showSWAL &&
                    <SweetAlert2
                        success
                        title="Utilisateur ajouté"
                        onConfirm={() => this.setState({showSWAL: false})}
                    >
                        L'utilisateur a bien été créé
                    </SweetAlert2>
                }

                {
                    this.state.showSWALConfirmEdit &&
                    <SweetAlert2
                        success
                        title="Mot de passe mis à jour"
                        onConfirm={() => this.setState({showSWALConfirmEdit: false})}
                    >
                        Le mot de passe de l'utilisateur a bien été mis à jour
                    </SweetAlert2>
                }

                {
                    this.state.showSWALConfirmDelete &&
                    <SweetAlert2
                        success
                        title="Suppression effectuée"
                        onConfirm={() => this.setState({showSWALConfirmDelete: false})}
                    >
                        L'utilisateur a bien été supprimé
                    </SweetAlert2>
                }

                {
                    this.state.showDeleteSWAL &&

                    <SweetAlert2
                        danger
                        showCancel
                        confirmBtnText="Supprimer"
                        cancelBtnText="Annuler"
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="default"
                        title="Suppression utilisateur"
                        onConfirm={() => this.handleDeleteUser(this.state.userToDelete, true)}
                    >
                        Êtes-vous sûr de vouloir supprimer l'utilisateur ? Cela supprimera le dossier SFTP ainsi que tout ce qu'il contient.
                    </SweetAlert2>
                }

                {
                    this.state.showEditSWAL &&
                    <SweetAlert2
                        input
                        showCancel
                        cancelBtnBsStyle="default"
                        title="Nouveau mot de passe"
                        placeHolder="Nouveau mot de passe"
                        onConfirm={this.handleEditUser.bind(this, this.state.userToEdit.id)}
                        onCancel={() => { this.setState({showEditSWAL: false})}}
                    />
                }
                <Container fluid={true}>
                    <Row>
                        <Col md={{size: 3, offset: 1}}>
                            <UserList
                                sftpUsers={this.state.sftpUsers}
                                deleteUserHandler={this.handleDeleteUser}
                                editUserHandler={this.handleEditUser}/>
                        </Col>
                        <Col md={{size: 3, offset: 4}}>
                            <UserForm addUserHandler={this.handleAddUser} userAdded={this.state.userAdded}/>
                        </Col>
                    </Row>
                </Container>
            </>
        );

    }
}

UserManager.propTypes = {
    style          : PropTypes.string,
    addAlert       : PropTypes.func,
    handleDeconnect: PropTypes.func.isRequired

};

export default withAxios(UserManager);
