import React from 'react';
import PropTypes from 'prop-types';

import imgLogo from '../../assets/autajon.png';
import SweetAlert2 from 'react-bootstrap-sweetalert';

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showEditSWAL: false
        };
    }

    openChangeAdminPasswordModal() {
        this.setState({showEditSWAL: true});
    }

    handleAdminPasswordChange(password) {
        this.props.handleChangeAdminPassword(password);
        this.setState({showEditSWAL: false});
    }

    render() {

        let navLinksMenu = (
            <ul className='navbar-nav'>

            </ul>
        );

        let navLinks = (
            <ul className='navbar-nav ml-auto'>
            </ul>
        );

        if (this.props.logged) {
            navLinks = (<>
                    <ul className='navbar-nav ml-auto'>
                        <li>
                            <span
                                tabIndex='0'
                                role='button'
                                className='nav-link'
                                style={{cursor: 'pointer'}}
                                onClick={this.openChangeAdminPasswordModal.bind(this)}
                            >Changer mot de passe ADMIN</span>
                        </li>
                        <li>
                        <span
                            tabIndex='0'
                            role='button'
                            className='nav-link'
                            style={{cursor: 'pointer'}}
                            onClick={this.props.handleDeconnect}
                        >Déconnexion</span>
                        </li>
                    </ul>
                </>
            );
        }

        return (
            <>
                {
                    this.state.showEditSWAL &&
                    <SweetAlert2
                        input
                        showCancel
                        inputType={"password"}
                        cancelBtnBsStyle="default"
                        title="Nouveau mot de passe"
                        placeHolder="Nouveau mot de passe"
                        onConfirm={this.handleAdminPasswordChange.bind(this)}
                        validation={"Vous devez entrer un mot de passe !"}
                        onCancel={() => { this.setState({showEditSWAL: false})}}
                    />
                }

                <nav className='navbar navbar-expand-lg navbar-dark mb-5 bg-primary' style={this.props.style}>
                    <div className='container-fluid'>
                    <span style={{color: "#fff"}}>
                        <img src={imgLogo} style={{width: '80px'}}/>
                        SFTP Autajon
                    </span>
                        <div id='main-nav' className='collapse navbar-collapse'>
                            {navLinksMenu}
                            {navLinks}
                        </div>
                    </div>
                </nav>

            </>
        );
    }

}

Header.propTypes = {
    style                    : PropTypes.object,
    logged                   : PropTypes.bool.isRequired,
    handleDeconnect          : PropTypes.func.isRequired,
    handleChangeAdminPassword: PropTypes.func.isRequired,
};

export default Header;
